import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from './Container';
import MealsSortedLogoBlack from '../svgs/MealsSortedLogoBlack';
import { RoutesEnum } from '../../App';
import DownloadIosOutline from '../svgs/DownloadIosOutline';
import { APP_DOWNLOAD_URL } from '../../pages/mealssorted/MealsSorted';

const FooterEl = styled.footer`
  background: ${props => props.theme.liquorice};
  padding: 76px 0;

  @media (min-width: 1440px) {
    padding: 76px 98px;
  }
`;

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NavItems = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const FooterLink = styled(NavLink)`
  color: ${props => props.theme.white};
  padding-left: 10px;
`;

const FooterExtLink = styled(Link)`
  color: ${props => props.theme.white};
`;

const Footer: React.FC = () => {
  return (
    <FooterEl>
      <FooterContainer>
        <NavItems>
          <Link to={`/${RoutesEnum.mealsSorted}`}>
            <MealsSortedLogoBlack />
          </Link>
          <FooterLink to={RoutesEnum.msPrivacyPolicy}>Privacy policy</FooterLink>
          <FooterExtLink to="/">Developed by Hive</FooterExtLink>
        </NavItems>
        {APP_DOWNLOAD_URL && (
          <Link to={APP_DOWNLOAD_URL}>
            <DownloadIosOutline />
          </Link>
        )}
      </FooterContainer>
    </FooterEl>
  );
};

export default Footer;
