import React from 'react';
import styled from 'styled-components';

import Hamburger from './svgs/Hamburger';
import LogoWordmark from './svgs/LogoWordmark';

const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid #383838;
  display: flex;
  flex-direction: row;
  padding: 16px 0;

  @media (min-width: 992px) {
    display: none;
  }
`;

const MenuButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: #BFBFBF;
  cursor: pointer;
  margin: 2px 0 0;
  padding: 0;

  &:hover {
    color: #55E4BA;
  }
`;

const HeaderLogo = styled.div`
  margin-left: 24px;
`;

interface MobileHeaderProps {
  onHamburgerClick: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ onHamburgerClick }) => {
  return (
    <Header>
      <MenuButton onClick={onHamburgerClick}>
        <Hamburger fill="currentcolor" />
      </MenuButton>
      <HeaderLogo>
        <LogoWordmark fill="#ECECEC" width={29} />
      </HeaderLogo>
    </Header>
  );
};

export default MobileHeader;
