import styled from 'styled-components';

export const TileHeading = styled.h3`
  color: #ECECEC;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 12px;
`;

const Tile = styled.div<{ direction: string; }>`
  background-color: #383838;
  border-radius: 3px;
  display: flex;
  flex-direction: ${(props) => props.direction};
  margin-bottom: 16px;
  padding: 16px 16px 5px;
`;

export default Tile;
