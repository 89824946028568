import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MealsSorted from './pages/mealssorted/MealsSorted';
import PrivacyPolicy from './pages/mealssorted/PrivacyPolicy';
import Home from './pages/mealssorted/Home';

export const enum RoutesEnum {
  mealsSorted = 'meals-sorted',
  msPrivacyPolicy = 'privacy-policy'
}

export const useClearTrailingSlash = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.match(/.*\/$/)) {
      navigate({ pathname: pathname.replace(/\/*$/, '')});
    }
  }, []);
};

const RouteApp = () => {
  useClearTrailingSlash();

  return <Outlet />;
};

const router = createBrowserRouter([
  {
    path: '',
    element: <RouteApp />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: RoutesEnum.mealsSorted,
        element: <MealsSorted />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: RoutesEnum.msPrivacyPolicy,
            element: <PrivacyPolicy />
          },
          {
            path: '*',
            element: <Navigate to={`/${RoutesEnum.mealsSorted}`} replace />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
