import React from 'react';

const MealsSortedBowl: React.FC = () => {
  return (
    <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.21872 11.7827L39.2731 1.85402C40.32 7.32106 39.2012 11.9974 36.7634 15.6494H3.9995C3.30969 14.4857 2.71056 13.1983 2.21872 11.7827ZM1.93164 15.6494C1.35797 14.5617 0.853031 13.3838 0.426858 12.1129C0.138784 11.2538 0.660149 10.3343 1.53735 10.0992L39.0215 0.0553986C39.8987 -0.179646 40.8099 0.355963 40.99 1.24399C42.1309 6.87054 41.1679 11.7428 38.8895 15.6494H40.4031C41.3112 15.6494 42.0528 16.4026 41.9969 17.307C40.4025 43.0969 1.59724 43.0969 0.00281239 17.307C-0.0530992 16.4026 0.688478 15.6494 1.59662 15.6494H1.93164Z" fill="#FF503C"/>
    </svg>
  );
};

export default MealsSortedBowl;
