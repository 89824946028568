import React from 'react';

const DownloadIos: React.FC = () => {
  return (
    <svg width="150" height="50" viewBox="0 0 150 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.556 48.9062C10.1752 48.9062 9.80352 48.9014 9.42566 48.8929C8.64289 48.8827 7.86194 48.8145 7.08924 48.689C6.36872 48.5649 5.67074 48.3341 5.01831 48.0041C4.37186 47.6769 3.78225 47.2478 3.27206 46.7334C2.75449 46.225 2.32358 45.6353 1.99642 44.9878C1.66566 44.336 1.43675 43.6374 1.31767 42.9163C1.18908 42.1414 1.1195 41.3579 1.10955 40.5725C1.10162 40.3089 1.09125 39.4311 1.09125 39.4311V10.5554C1.09125 10.5554 1.1023 9.69116 1.10961 9.43725C1.11914 8.6531 1.18831 7.87082 1.31652 7.09716C1.43582 6.37406 1.66491 5.67344 1.99585 5.01954C2.3218 4.37243 2.75032 3.78233 3.26477 3.2721C3.77865 2.75703 4.37015 2.32576 5.0177 1.99401C5.66863 1.66512 6.36529 1.43592 7.08435 1.31409C7.85959 1.1873 8.64325 1.11875 9.42874 1.10901L10.5566 1.09375H139.017L140.159 1.10963C140.937 1.11887 141.714 1.18681 142.482 1.31286C143.208 1.43622 143.912 1.66702 144.57 1.99768C145.868 2.66623 146.923 3.72395 147.589 5.02259C147.915 5.67197 148.14 6.36688 148.258 7.08374C148.388 7.86374 148.46 8.65217 148.475 9.44275C148.479 9.79675 148.479 10.177 148.479 10.5554C148.489 11.0242 148.489 11.4703 148.489 11.9202V38.081C148.489 38.5351 148.489 38.9783 148.479 39.425C148.479 39.8315 148.479 40.2039 148.474 40.5871C148.46 41.3636 148.388 42.138 148.26 42.904C148.144 43.6303 147.916 44.3344 147.585 44.9915C147.256 45.632 146.828 46.2166 146.316 46.7236C145.805 47.2409 145.215 47.6724 144.567 48.0018C143.91 48.3342 143.207 48.5659 142.482 48.689C141.709 48.8152 140.928 48.8834 140.145 48.8929C139.779 48.9014 139.396 48.9062 139.023 48.9062L137.668 48.9087L10.556 48.9062Z" fill="#2C2429"/>
      <path d="M30.9611 25.3759C30.9745 24.3325 31.2516 23.3096 31.7667 22.4021C32.2817 21.4947 33.0179 20.7322 33.9068 20.1858C33.3421 19.3793 32.5972 18.7157 31.7311 18.2475C30.8651 17.7793 29.9018 17.5195 28.9178 17.4887C26.8188 17.2684 24.7838 18.7447 23.7142 18.7447C22.6238 18.7447 20.977 17.5106 19.2036 17.547C18.0565 17.5841 16.9385 17.9177 15.9587 18.5152C14.9788 19.1128 14.1705 19.954 13.6124 20.9569C11.1949 25.1424 12.9981 31.2937 15.3139 34.677C16.4725 36.3337 17.8266 38.1842 19.5984 38.1186C21.3323 38.0467 21.9798 37.013 24.0727 37.013C26.1461 37.013 26.7537 38.1186 28.5614 38.0769C30.4219 38.0467 31.5941 36.4128 32.7121 34.7405C33.5446 33.56 34.1851 32.2553 34.6101 30.8748C33.5292 30.4177 32.6068 29.6524 31.9579 28.6746C31.309 27.6967 30.9623 26.5494 30.9611 25.3759Z" fill="white"/>
      <path d="M27.5465 15.2636C28.5609 14.0459 29.0607 12.4806 28.9397 10.9003C27.3899 11.0631 25.9583 11.8038 24.9302 12.9748C24.4275 13.5469 24.0425 14.2125 23.7972 14.9334C23.5519 15.6544 23.4511 16.4166 23.5006 17.1766C24.2758 17.1846 25.0426 17.0166 25.7435 16.6852C26.4443 16.3538 27.0608 15.8678 27.5465 15.2636Z" fill="white"/>
      <path d="M52.8778 33.9246H46.9611L45.5402 38.1201H43.0341L48.6383 22.5976H51.2421L56.8463 38.1201H54.2975L52.8778 33.9246ZM47.5738 31.9885H52.2638L49.9518 25.1794H49.8871L47.5738 31.9885Z" fill="white"/>
      <path d="M68.9496 32.4622C68.9496 35.979 67.0672 38.2385 64.2267 38.2385C63.5071 38.2762 62.7915 38.1104 62.1617 37.7602C61.532 37.4101 61.0136 36.8896 60.6659 36.2585H60.6121V41.864H58.2891V26.803H60.5377V28.6853H60.5804C60.9441 28.0572 61.4713 27.5395 62.1058 27.1872C62.7404 26.8349 63.4585 26.6612 64.1839 26.6846C67.0562 26.6846 68.9496 28.9551 68.9496 32.4622ZM66.5619 32.4622C66.5619 30.1709 65.3778 28.6646 63.5711 28.6646C61.7962 28.6646 60.6024 30.2026 60.6024 32.4622C60.6024 34.7424 61.7962 36.2695 63.5711 36.2695C65.3778 36.2695 66.5619 34.7742 66.5619 32.4622Z" fill="white"/>
      <path d="M81.4056 32.4622C81.4056 35.979 79.5233 38.2385 76.6827 38.2385C75.9631 38.2762 75.2475 38.1104 74.6178 37.7602C73.988 37.4101 73.4696 36.8896 73.1219 36.2585H73.0682V41.864H70.7452V26.803H72.9937V28.6853H73.0364C73.4001 28.0572 73.9273 27.5395 74.5618 27.1872C75.1964 26.8349 75.9145 26.6612 76.64 26.6846C79.5123 26.6846 81.4056 28.9551 81.4056 32.4622ZM79.0179 32.4622C79.0179 30.1709 77.8338 28.6646 76.0272 28.6646C74.2523 28.6646 73.0584 30.2026 73.0584 32.4622C73.0584 34.7424 74.2523 36.2695 76.0272 36.2695C77.8338 36.2695 79.0179 34.7742 79.0179 32.4622Z" fill="white"/>
      <path d="M89.6381 33.7952C89.8102 35.3345 91.3056 36.3452 93.349 36.3452C95.307 36.3452 96.7157 35.3344 96.7157 33.9465C96.7157 32.7416 95.8661 32.0202 93.8544 31.5258L91.8427 31.0412C88.9923 30.3527 87.6691 29.0197 87.6691 26.8566C87.6691 24.1784 90.0031 22.3388 93.3173 22.3388C96.5973 22.3388 98.8458 24.1784 98.9215 26.8566H96.5765C96.4361 25.3076 95.1556 24.3725 93.2843 24.3725C91.4129 24.3725 90.1324 25.3186 90.1324 26.6955C90.1324 27.7929 90.9503 28.4387 92.951 28.933L94.6612 29.3529C97.846 30.1061 99.1693 31.3854 99.1693 33.6559C99.1693 36.56 96.856 38.3788 93.1768 38.3788C89.7345 38.3788 87.4103 36.6027 87.2601 33.7951L89.6381 33.7952Z" fill="white"/>
      <path d="M104.183 24.1248V26.803H106.335V28.6426H104.183V34.8816C104.183 35.8508 104.614 36.3025 105.56 36.3025C105.815 36.298 106.07 36.2801 106.324 36.2487V38.0774C105.899 38.1568 105.466 38.1928 105.034 38.1848C102.742 38.1848 101.849 37.3242 101.849 35.1294V28.6426H100.203V26.803H101.849V24.1248H104.183Z" fill="white"/>
      <path d="M107.581 32.4622C107.581 28.9014 109.678 26.6638 112.949 26.6638C116.23 26.6638 118.317 28.9013 118.317 32.4622C118.317 36.0327 116.241 38.2605 112.949 38.2605C109.658 38.2605 107.581 36.0327 107.581 32.4622ZM115.95 32.4622C115.95 30.0195 114.831 28.5779 112.949 28.5779C111.066 28.5779 109.948 30.0305 109.948 32.4622C109.948 34.9146 111.066 36.3452 112.949 36.3452C114.831 36.3452 115.95 34.9146 115.95 32.4622Z" fill="white"/>
      <path d="M120.233 26.803H122.448V28.7292H122.502C122.652 28.1276 123.004 27.596 123.5 27.2236C123.996 26.8512 124.604 26.6609 125.224 26.6846C125.492 26.6836 125.759 26.7127 126.02 26.7712V28.9441C125.682 28.8408 125.329 28.7934 124.976 28.8037C124.639 28.79 124.302 28.8495 123.99 28.9781C123.678 29.1067 123.397 29.3014 123.167 29.5488C122.937 29.7961 122.763 30.0903 122.658 30.4112C122.552 30.732 122.517 31.0719 122.556 31.4075V38.1201H120.233L120.233 26.803Z" fill="white"/>
      <path d="M136.73 34.7961C136.418 36.8506 134.417 38.2605 131.857 38.2605C128.565 38.2605 126.522 36.0547 126.522 32.5159C126.522 28.9661 128.576 26.6638 131.76 26.6638C134.891 26.6638 136.86 28.8147 136.86 32.2461V33.042H128.867V33.1824C128.83 33.5989 128.882 34.0185 129.02 34.4133C129.157 34.8081 129.377 35.169 129.665 35.4722C129.953 35.7754 130.302 36.0139 130.69 36.1717C131.077 36.3296 131.493 36.4033 131.911 36.3879C132.46 36.4394 133.011 36.3122 133.482 36.0254C133.953 35.7386 134.318 35.3075 134.525 34.7961L136.73 34.7961ZM128.878 31.4185H134.536C134.556 31.044 134.5 30.6692 134.369 30.3177C134.238 29.9662 134.036 29.6455 133.775 29.3758C133.515 29.1061 133.201 28.8932 132.854 28.7504C132.507 28.6076 132.135 28.5381 131.76 28.5461C131.381 28.5439 131.006 28.6166 130.656 28.76C130.306 28.9034 129.988 29.1148 129.72 29.3818C129.452 29.6488 129.24 29.9663 129.095 30.3159C128.951 30.6654 128.877 31.0402 128.878 31.4185Z" fill="white"/>
      <path d="M47.2827 10.9138C47.7697 10.8788 48.2584 10.9524 48.7136 11.1292C49.1687 11.306 49.5789 11.5816 49.9147 11.9361C50.2504 12.2906 50.5033 12.7152 50.655 13.1793C50.8068 13.6434 50.8536 14.1354 50.7922 14.6198C50.7922 17.0026 49.5044 18.3723 47.2827 18.3723H44.5886V10.9138H47.2827ZM45.7471 17.3175H47.1533C47.5013 17.3383 47.8496 17.2812 48.1728 17.1505C48.4959 17.0197 48.7859 16.8185 49.0215 16.5616C49.2571 16.3046 49.4325 15.9983 49.5348 15.6651C49.6371 15.3318 49.6638 14.9799 49.613 14.635C49.6602 14.2915 49.6307 13.9417 49.5268 13.6109C49.4229 13.2801 49.2471 12.9763 49.0121 12.7214C48.777 12.4665 48.4884 12.2667 48.1671 12.1365C47.8457 12.0062 47.4995 11.9486 47.1533 11.9678H45.7471V17.3175Z" fill="white"/>
      <path d="M52.1008 15.5554C52.0654 15.1855 52.1077 14.8123 52.225 14.4597C52.3423 14.1071 52.532 13.783 52.782 13.508C53.0319 13.233 53.3365 13.0133 53.6763 12.863C54.0162 12.7126 54.3836 12.6349 54.7552 12.6349C55.1268 12.6349 55.4943 12.7126 55.8341 12.863C56.1739 13.0133 56.4786 13.233 56.7285 13.508C56.9784 13.783 57.1681 14.1071 57.2854 14.4597C57.4027 14.8123 57.445 15.1855 57.4097 15.5554C57.4457 15.9257 57.4039 16.2994 57.2869 16.6526C57.1699 17.0057 56.9803 17.3305 56.7303 17.606C56.4804 17.8815 56.1755 18.1017 55.8353 18.2524C55.4952 18.4031 55.1273 18.4809 54.7552 18.4809C54.3832 18.4809 54.0153 18.4031 53.6751 18.2524C53.335 18.1017 53.0301 17.8815 52.7801 17.606C52.5301 17.3305 52.3405 17.0057 52.2236 16.6526C52.1066 16.2994 52.0648 15.9257 52.1008 15.5554ZM56.2671 15.5554C56.2671 14.3353 55.719 13.6218 54.7571 13.6218C53.7915 13.6218 53.2483 14.3353 53.2483 15.5554C53.2483 16.7853 53.7915 17.4933 54.7571 17.4933C55.719 17.4933 56.2671 16.7804 56.2671 15.5554Z" fill="white"/>
      <path d="M64.4665 18.3722H63.3142L62.1509 14.2267H62.063L60.9045 18.3722H59.7632L58.2117 12.7435H59.3384L60.3467 17.0385H60.4297L61.5869 12.7435H62.6526L63.8098 17.0385H63.8977L64.9011 12.7435H66.012L64.4665 18.3722Z" fill="white"/>
      <path d="M67.3169 12.7435H68.3862V13.6377H68.4692C68.6101 13.3165 68.8476 13.0473 69.1487 12.8676C69.4497 12.6878 69.7994 12.6065 70.1489 12.6349C70.4228 12.6143 70.6978 12.6556 70.9536 12.7557C71.2093 12.8558 71.4392 13.0122 71.6263 13.2133C71.8134 13.4143 71.9529 13.6549 72.0344 13.9172C72.1159 14.1794 72.1374 14.4567 72.0972 14.7284V18.3721H70.9863V15.0073C70.9863 14.1028 70.5933 13.653 69.7717 13.653C69.5858 13.6443 69.4002 13.6759 69.2276 13.7457C69.055 13.8155 68.8996 13.9218 68.7719 14.0573C68.6443 14.1928 68.5474 14.3542 68.488 14.5306C68.4286 14.7071 68.408 14.8942 68.4277 15.0793V18.3722H67.3169L67.3169 12.7435Z" fill="white"/>
      <path d="M73.8672 10.5462H74.978V18.3722H73.8672V10.5462Z" fill="white"/>
      <path d="M76.5222 15.5554C76.4868 15.1855 76.5292 14.8123 76.6465 14.4597C76.7638 14.1071 76.9536 13.7829 77.2035 13.5079C77.4535 13.2329 77.7581 13.0132 78.098 12.8629C78.4378 12.7125 78.8053 12.6348 79.1769 12.6348C79.5485 12.6348 79.916 12.7125 80.2559 12.8629C80.5957 13.0132 80.9004 13.2329 81.1503 13.5079C81.4003 13.7829 81.59 14.1071 81.7073 14.4597C81.8247 14.8123 81.867 15.1855 81.8316 15.5554C81.8677 15.9257 81.8258 16.2995 81.7088 16.6526C81.5918 17.0058 81.4021 17.3305 81.1521 17.606C80.9021 17.8815 80.5972 18.1017 80.2571 18.2524C79.9169 18.403 79.549 18.4809 79.1769 18.4809C78.8049 18.4809 78.437 18.403 78.0968 18.2524C77.7566 18.1017 77.4517 17.8815 77.2017 17.606C76.9517 17.3305 76.7621 17.0058 76.6451 16.6526C76.528 16.2995 76.4862 15.9257 76.5222 15.5554ZM80.6884 15.5554C80.6884 14.3353 80.1403 13.6218 79.1784 13.6218C78.2129 13.6218 77.6697 14.3353 77.6697 15.5554C77.6697 16.7853 78.2129 17.4933 79.1784 17.4933C80.1404 17.4933 80.6884 16.7804 80.6884 15.5554Z" fill="white"/>
      <path d="M83.0011 16.7804C83.0011 15.7672 83.7555 15.1831 85.0946 15.1001L86.6193 15.0122V14.5264C86.6193 13.9319 86.2262 13.5962 85.4669 13.5962C84.8468 13.5962 84.4171 13.8239 84.2938 14.2218H83.2184C83.3319 13.255 84.2413 12.6349 85.5182 12.6349C86.9293 12.6349 87.7252 13.3374 87.7252 14.5264V18.3722H86.6559V17.5812H86.568C86.3896 17.8649 86.1391 18.0962 85.8421 18.2515C85.545 18.4068 85.2121 18.4804 84.8773 18.4649C84.641 18.4895 84.4022 18.4643 84.1763 18.3909C83.9503 18.3176 83.7422 18.1976 83.5655 18.0389C83.3887 17.8802 83.2471 17.6862 83.1499 17.4695C83.0527 17.2527 83.002 17.018 83.0011 16.7804ZM86.6193 16.2994V15.8289L85.2448 15.9167C84.4696 15.9686 84.118 16.2323 84.118 16.7285C84.118 17.2351 84.5575 17.5299 85.1618 17.5299C85.3388 17.5478 85.5177 17.5299 85.6877 17.4773C85.8577 17.4247 86.0154 17.3384 86.1514 17.2236C86.2874 17.1088 86.3989 16.9679 86.4792 16.8091C86.5596 16.6503 86.6072 16.477 86.6193 16.2994Z" fill="white"/>
      <path d="M89.1852 15.5554C89.1852 13.7769 90.0995 12.6501 91.5216 12.6501C91.8734 12.6339 92.2225 12.7182 92.5282 12.8931C92.8338 13.0679 93.0834 13.3262 93.2477 13.6376H93.3307V10.5462H94.4415V18.3722H93.3771V17.4829H93.2892C93.1122 17.7923 92.8539 18.0473 92.5424 18.2204C92.2308 18.3935 91.8778 18.4781 91.5216 18.4649C90.0897 18.465 89.1852 17.3383 89.1852 15.5554ZM90.3327 15.5554C90.3327 16.7493 90.8954 17.4677 91.8366 17.4677C92.7728 17.4677 93.3515 16.7389 93.3515 15.5603C93.3515 14.3872 92.7668 13.6481 91.8366 13.6481C90.9015 13.6481 90.3327 14.3713 90.3327 15.5554Z" fill="white"/>
      <path d="M99.0375 15.5554C99.0021 15.1855 99.0444 14.8123 99.1617 14.4597C99.279 14.1071 99.4687 13.783 99.7186 13.508C99.9686 13.233 100.273 13.0133 100.613 12.863C100.953 12.7126 101.32 12.6349 101.692 12.6349C102.063 12.6349 102.431 12.7126 102.771 12.863C103.111 13.0133 103.415 13.233 103.665 13.508C103.915 13.783 104.105 14.1071 104.222 14.4597C104.339 14.8123 104.382 15.1855 104.346 15.5554C104.382 15.9257 104.341 16.2994 104.224 16.6526C104.107 17.0057 103.917 17.3305 103.667 17.606C103.417 17.8815 103.112 18.1017 102.772 18.2524C102.432 18.4031 102.064 18.4809 101.692 18.4809C101.32 18.4809 100.952 18.4031 100.612 18.2524C100.272 18.1017 99.9668 17.8815 99.7168 17.606C99.4668 17.3305 99.2772 17.0057 99.1602 16.6526C99.0432 16.2994 99.0014 15.9257 99.0375 15.5554ZM103.204 15.5554C103.204 14.3353 102.656 13.6218 101.694 13.6218C100.728 13.6218 100.185 14.3353 100.185 15.5554C100.185 16.7853 100.728 17.4933 101.694 17.4933C102.656 17.4933 103.204 16.7804 103.204 15.5554Z" fill="white"/>
      <path d="M105.837 12.7435H106.906V13.6377H106.989C107.13 13.3165 107.367 13.0473 107.669 12.8676C107.97 12.6878 108.319 12.6065 108.669 12.6349C108.943 12.6143 109.218 12.6556 109.473 12.7557C109.729 12.8558 109.959 13.0122 110.146 13.2133C110.333 13.4143 110.473 13.6549 110.554 13.9172C110.636 14.1794 110.657 14.4567 110.617 14.7284V18.3721H109.506V15.0073C109.506 14.1028 109.113 13.653 108.292 13.653C108.106 13.6443 107.92 13.6759 107.747 13.7457C107.575 13.8155 107.419 13.9218 107.292 14.0573C107.164 14.1928 107.067 14.3542 107.008 14.5306C106.948 14.7071 106.928 14.8942 106.948 15.0793V18.3722H105.837V12.7435Z" fill="white"/>
      <path d="M116.894 11.3422V12.7692H118.113V13.7048H116.894V16.5991C116.894 17.1887 117.137 17.4469 117.69 17.4469C117.831 17.4464 117.973 17.4379 118.113 17.4213V18.3466C117.914 18.3822 117.712 18.4012 117.509 18.4033C116.274 18.4033 115.782 17.9687 115.782 16.8835V13.7048H114.888V12.7691H115.782V11.3422H116.894Z" fill="white"/>
      <path d="M119.631 10.5462H120.732V13.6481H120.82C120.967 13.3239 121.211 13.0532 121.519 12.8727C121.826 12.6923 122.181 12.6109 122.536 12.6398C122.808 12.6249 123.081 12.6704 123.334 12.773C123.586 12.8755 123.814 13.0326 123.999 13.233C124.184 13.4335 124.322 13.6723 124.405 13.9324C124.487 14.1926 124.511 14.4677 124.474 14.7381V18.3722H123.362V15.0122C123.362 14.1131 122.944 13.6578 122.159 13.6578C121.968 13.6422 121.776 13.6684 121.596 13.7347C121.416 13.801 121.253 13.9058 121.118 14.0417C120.983 14.1777 120.88 14.3414 120.815 14.5216C120.75 14.7018 120.725 14.894 120.742 15.0848V18.3722H119.631L119.631 10.5462Z" fill="white"/>
      <path d="M130.952 16.8524C130.801 17.3668 130.474 17.8118 130.028 18.1095C129.582 18.4072 129.046 18.5386 128.513 18.4808C128.142 18.4906 127.773 18.4196 127.433 18.2727C127.092 18.1258 126.787 17.9065 126.54 17.6301C126.293 17.3537 126.108 17.0267 126 16.6719C125.892 16.3171 125.862 15.943 125.912 15.5756C125.863 15.207 125.893 14.832 126.002 14.4762C126.11 14.1204 126.293 13.7919 126.539 13.5131C126.785 13.2342 127.088 13.0115 127.428 12.86C127.768 12.7085 128.136 12.6317 128.508 12.6349C130.074 12.6349 131.019 13.7049 131.019 15.4724V15.86H127.044V15.9223C127.027 16.1288 127.053 16.3367 127.12 16.5327C127.188 16.7286 127.296 16.9082 127.437 17.0601C127.578 17.2119 127.749 17.3325 127.94 17.4143C128.13 17.496 128.336 17.5371 128.543 17.5348C128.809 17.5667 129.078 17.5188 129.316 17.3973C129.555 17.2758 129.752 17.0861 129.882 16.8524L130.952 16.8524ZM127.044 15.0385H129.887C129.901 14.8495 129.875 14.6598 129.812 14.4814C129.748 14.303 129.648 14.1399 129.517 14.0026C129.387 13.8653 129.229 13.7567 129.054 13.684C128.879 13.6113 128.691 13.576 128.502 13.5803C128.309 13.5779 128.119 13.614 127.941 13.6864C127.763 13.7588 127.601 13.8662 127.465 14.0021C127.33 14.138 127.222 14.2997 127.15 14.4777C127.078 14.6557 127.042 14.8463 127.044 15.0385Z" fill="white"/>
    </svg>
  );
};

export default DownloadIos;
