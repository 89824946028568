import React from 'react';

interface HamburgerProps {
  fill?: string;
  width?: number;
}

const Hamburger = ({ fill = '#BFBFBF', width = 18 }: HamburgerProps) => {
  const height = width * 0.67;

  return (
    <svg width={width} height={height} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill={fill} />
    </svg>
  );
}

export default Hamburger;
