import React from 'react';

interface LogoWordmarkProps {
  fill: string;
  width: number;
}

const LogoWordmark = ({ fill = 'white', width = 190 }: LogoWordmarkProps) => {
  const height = width * 0.39;

  return (
    <svg width={width} height={height} viewBox="0 0 190 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M74.819 15C79.5134 15 83.319 11.6421 83.319 7.5C83.319 3.35786 79.5134 0 74.819 0C70.1246 0 66.319 3.35786 66.319 7.5C66.319 11.6421 70.1246 15 74.819 15ZM81.819 72.6964V22.6964H67.819V72.6964H81.819ZM164.897 32.3826C157.928 32.3826 154.09 37.6346 153.787 42.1796H176.007C175.805 37.2306 172.573 32.3826 164.897 32.3826ZM166.008 62.6826C172.27 62.6826 175.704 59.5516 177.32 55.1076L188.531 58.4406C186.006 67.0256 178.128 74.1966 165.907 74.1966C152.272 74.1966 140.253 64.3996 140.253 47.6336C140.253 31.7766 151.969 21.4746 164.695 21.4746C180.047 21.4746 189.238 31.2716 189.238 47.2296C189.238 48.6777 189.123 50.1832 189.067 50.9218L189.066 50.9228L189.066 50.9231C189.048 51.1625 189.036 51.3211 189.036 51.3706H153.484C153.787 57.9356 159.342 62.6826 166.008 62.6826ZM125.714 22.9896H139.854L120.563 72.6816H107.13L86.829 22.9896H101.575L113.897 56.3196L125.714 22.9896ZM59.186 1.07263V72.6816H45.147V42.9876H13.938V72.6816H0V1.07263H13.938V29.8576H45.147V1.07263H59.186Z" fill={fill} />
    </svg>
  )
}

export default LogoWordmark;
