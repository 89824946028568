import React from 'react';

const MealsSortedLogo: React.FC = () => {
  return (
    <svg width="164" height="15" viewBox="0 0 164 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.52592 7.04668V14.609H0V0.916642H4.20127L8.49803 6.03455L12.8903 0.916642H16.9388V14.609H12.4319V7.00849L8.55532 11.3625H8.23067L4.52592 7.04668Z" fill="#FF503C"/>
      <path d="M33.3437 11.0761C32.3889 13.8451 29.9827 14.9718 25.7623 14.9718C22.9742 14.9718 21.16 14.3989 20.0333 13.3677C18.9066 12.3365 18.4674 10.9615 18.4674 9.35738C18.4674 8.17338 18.7538 7.18036 19.2886 6.22552C20.4153 4.35405 22.6305 3.20825 26.1061 3.20825C27.7866 3.20825 29.1615 3.4565 30.231 3.97211C32.408 4.96514 33.4392 6.77933 33.4392 9.10912V10.1976H22.9551C23.2416 11.3052 24.1582 11.8399 26.0488 11.8399C27.481 11.8399 28.073 11.5535 28.4359 11.0761H33.3437ZM26.0488 6.3783C24.3301 6.3783 23.3943 6.9321 23.0315 7.96332H28.8751C28.5886 6.81752 27.7102 6.3783 26.0488 6.3783Z" fill="#FF503C"/>
      <path d="M44.5105 12.3747C43.6703 13.9024 41.837 14.9718 38.9152 14.9718C35.9552 14.9718 34.4656 13.9597 34.4656 11.8781V11.859C34.4656 10.5605 35.0195 9.58654 36.2225 9.03274C36.5854 8.86087 36.9673 8.70809 37.3683 8.61261C38.1895 8.38345 39.3162 8.23067 41.1877 8.097C42.0852 8.02061 42.7345 7.96332 43.1928 7.88693C44.1477 7.75326 44.3959 7.56229 44.3959 7.19945C44.3959 6.68384 43.9376 6.24462 42.2762 6.24462C40.2519 6.24462 39.5645 6.72204 39.4881 7.56229H34.7903C35.1149 4.6023 37.693 3.22734 42.0661 3.22734C46.6493 3.22734 48.8264 4.46863 48.8264 8.0588V14.609H44.9306L44.5105 12.3747ZM40.7103 12.1264C41.3787 12.1264 42.0088 12.0118 42.5626 11.7636C43.6893 11.3052 44.415 10.5032 44.415 9.62473V9.51015C44.1095 9.8157 43.3647 10.0258 42.1616 10.1785C40.7485 10.3122 40.1183 10.4268 39.7554 10.6369C39.4499 10.8087 39.278 11.0379 39.278 11.3434V11.3625C39.278 11.8399 39.679 12.1264 40.7103 12.1264Z" fill="#FF503C"/>
      <path d="M50.778 14.609V0H55.3803V14.609H50.778Z" fill="#FF503C"/>
      <path d="M63.909 14.9718C59.7077 14.9718 57.0342 13.8833 56.4804 10.9615H61.3501C61.6747 11.7636 62.4577 12.05 64.3482 12.05C65.9333 12.05 66.4298 11.7063 66.4298 11.2289C66.4298 11.0761 66.3343 10.9424 66.1624 10.8469C65.8378 10.6369 64.902 10.5414 62.9351 10.465C61.4455 10.3886 60.2997 10.2358 59.4022 10.0067C57.6071 9.54835 56.8814 8.6699 56.8814 7.16126C56.8814 4.73598 59.0203 3.20825 63.3361 3.20825C67.5756 3.20825 69.8481 4.37314 70.7265 7.06578H66.086C65.7805 6.24462 64.7302 5.99636 63.2215 5.99636C61.6174 5.99636 61.1973 6.3401 61.1973 6.77933C61.1973 6.9321 61.2928 7.08487 61.5028 7.18036C61.9039 7.39042 62.8396 7.505 64.5774 7.58139C66.1624 7.65777 67.4037 7.81055 68.3204 8.0588C70.1345 8.53622 70.8029 9.43376 70.8029 10.9424C70.8029 11.7445 70.5547 12.4319 70.0772 13.043C69.1224 14.2461 67.1554 14.9718 63.909 14.9718Z" fill="#FF503C"/>
      <path d="M84.3162 15.01C82.4256 15.01 80.9361 14.819 79.7521 14.4371C77.4414 13.6923 76.3529 12.1837 75.9519 9.93028H81.0507C81.3753 11.1907 82.7503 11.5726 84.6218 11.5726C86.3596 11.5726 87.1043 11.267 87.1043 10.5414C87.1043 10.2549 86.9707 10.0449 86.7415 9.91118C86.2641 9.62473 85.0419 9.51015 82.5402 9.35738C78.3962 9.07093 76.4484 8.02061 76.4484 5.2134C76.4484 2.2916 78.9882 0.515611 83.5905 0.515611C88.2692 0.515611 90.8282 1.83328 91.6493 5.36617H86.7797C86.4932 4.22037 85.3474 3.89572 83.4569 3.89572C81.6618 3.89572 81.0889 4.33495 81.0889 4.94604C81.0889 5.27069 81.2416 5.49985 81.7764 5.65262C82.3302 5.80539 83.3614 5.93907 85.1183 6.03455C86.6269 6.11094 87.83 6.30191 88.823 6.58836C90.79 7.18036 91.7257 8.26887 91.7257 10.2167C91.7257 11.1907 91.4775 12.0118 90.9428 12.7375C89.9115 14.1697 87.7727 15.01 84.3162 15.01Z" fill="#FF503C"/>
      <path d="M100.361 14.9718C98.6037 14.9718 97.1714 14.7426 96.102 14.2652C93.925 13.3104 92.9893 11.5726 92.9893 9.16641C92.9893 7.96332 93.2948 6.9321 93.8868 6.03455C95.0899 4.23947 97.3433 3.20825 100.418 3.20825C101.984 3.20825 103.282 3.4565 104.409 3.93392C106.643 4.92695 107.827 6.74113 107.827 9.16641C107.827 10.3504 107.541 11.3434 107.006 12.2219C105.88 13.9597 103.722 14.9718 100.361 14.9718ZM100.399 11.3816C102.347 11.3816 103.187 10.5987 103.187 9.16641C103.187 7.56229 102.289 6.76023 100.418 6.76023C98.5846 6.76023 97.6488 7.56229 97.6488 9.16641C97.6488 10.656 98.5655 11.3816 100.399 11.3816Z" fill="#FF503C"/>
      <path d="M109.392 14.609V3.53289H113.268L113.822 6.72204C114.261 4.46863 115.56 3.36102 118.1 3.36102H119.303V7.92513H117.374C116.496 7.92513 115.846 8.03971 115.388 8.21158C114.471 8.61261 114.185 9.51015 114.185 11.1143V14.609H109.392Z" fill="#FF503C"/>
      <path d="M131.562 14.609H128.449C127.074 14.609 126.005 14.4371 125.241 14.0552C123.713 13.3104 123.179 11.7636 123.179 9.12822V7.29494H120.601V3.76205C121.345 3.74295 121.995 3.70476 122.51 3.57108C123.179 3.39921 123.446 3.09366 123.675 2.46347C123.885 1.90967 123.923 1.10761 123.942 0.477418H127.475V3.53289H131.562V7.29494H127.475V8.70809C127.475 9.31919 127.533 9.7966 127.628 10.1022C127.857 10.7323 128.43 10.8851 129.652 10.8851H131.562V14.609Z" fill="#FF503C"/>
      <path d="M147.458 11.0761C146.503 13.8451 144.097 14.9718 139.876 14.9718C137.088 14.9718 135.274 14.3989 134.147 13.3677C133.021 12.3365 132.581 10.9615 132.581 9.35738C132.581 8.17338 132.868 7.18036 133.402 6.22552C134.529 4.35405 136.744 3.20825 140.22 3.20825C141.9 3.20825 143.275 3.4565 144.345 3.97211C146.522 4.96514 147.553 6.77933 147.553 9.10912V10.1976H137.069C137.355 11.3052 138.272 11.8399 140.163 11.8399C141.595 11.8399 142.187 11.5535 142.55 11.0761H147.458ZM140.163 6.3783C138.444 6.3783 137.508 6.9321 137.145 7.96332H142.989C142.703 6.81752 141.824 6.3783 140.163 6.3783Z" fill="#FF503C"/>
      <path d="M159.118 12.6802C158.373 14.0361 156.979 14.8572 154.63 14.8572C153.561 14.8572 152.587 14.6663 151.708 14.227C149.951 13.3868 148.825 11.7254 148.825 9.18551C148.825 7.92513 149.092 6.87481 149.569 5.97727C150.601 4.22037 152.415 3.28463 154.745 3.28463C156.75 3.28463 158.106 3.91482 158.927 5.25159V0H163.395V14.609H159.385L159.118 12.6802ZM156.234 11.3816C158.106 11.3816 159.022 10.656 159.022 9.16641C159.022 7.60048 157.991 6.76023 156.234 6.76023C154.363 6.76023 153.484 7.60048 153.484 9.16641C153.484 10.656 154.401 11.3816 156.234 11.3816Z" fill="#FF503C"/>
    </svg>
  );
};

export default MealsSortedLogo;
