import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    padding-top: 50px;
  }
`;

const Title = styled.h1`
  font-family: ClashDisplay-Bold, sans-serif;
  font-size: 64px;
  line-height: 1.1;
  margin: 0 0 40px;

  @media (min-width: 992px) {
    margin-bottom: 0;
    white-space: pre;
  }
`;

const Content = styled.section`
  margin-bottom: 75px;
  max-width: 695px;
`;

const SectionHeading = styled.h3`
  margin: 30px 0 20px;
`;

const BoldText = styled.p`
  font-weight: 700;
`;

const Text = styled.p``;

const List = styled.ul``;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const TextLink = styled(Link)`
  color: ${props => props.theme.berry};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

interface PrivacySectionProps {
  heading: string;
  index: number;
}

const PrivacySection: React.FC<PropsWithChildren<PrivacySectionProps>> = ({ children, heading, index }) => (
  <>
    <SectionHeading>{`${index}. ${heading}`}</SectionHeading>
    {children}
  </>
);

const PrivacyPolicy: React.FC = () => {
  return (
    <ContentWrapper>
      <Title>{'Privacy\nPolicy'}</Title>
      <Content>
        <BoldText>{'Last updated: 26/10/2024'}</BoldText>
        <Text>
          {`The Hive Team Limited ("we", "us", or "our") operates the Meals Sorted mobile application (the "Service").
          This page informs you of our policies regarding the collection, use, and disclosure of personal data when you
          use our Service.`}
        </Text>
        <PrivacySection heading="Information Collection and Use" index={1}>
          <Text>Meals Sorted collects and stores the following information:</Text>
          <List>
            <ListItem>Meal names and recipes you input</ListItem>
            <ListItem>Grocery items you add to your lists</ListItem>
            <ListItem>Custom labels you create</ListItem>
            <ListItem>Weekly meal plans you generate</ListItem>
          </List>
          <Text>This information is stored locally on your device and is not transmitted to our servers.</Text>
        </PrivacySection>
        <PrivacySection heading="Use of Data" index={2}>
          <Text>The data you input is used solely for the functionality of the app, including:</Text>
          <List>
            <ListItem>Displaying your meals and recipes</ListItem>
            <ListItem>Generating grocery lists</ListItem>
            <ListItem>Creating and displaying weekly meal plans</ListItem>
          </List>
          <Text>We do not use your data for any other purposes, such as marketing or analytics.</Text>
        </PrivacySection>
        <PrivacySection heading="Data Storage and Security" index={3}>
          <Text>
            {`All data is stored locally on your device. We do not have access to this data, nor is it transmitted
            over the internet. The security of your data is protected by your device's built-in security features.`}
          </Text>
        </PrivacySection>
        <PrivacySection heading="Third-Party Services" index={4}>
          <Text>Meals Sorted does not use any third-party services that would have access to your data.</Text>
        </PrivacySection>
        <PrivacySection heading="Changes to This Privacy Policy" index={5}>
          <Text>
            {`We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page and updating the "Last updated" date.`}
          </Text>
        </PrivacySection>
        <PrivacySection heading="Your Rights" index={6}>
          <Text>
            {`You have full control over your data within the app. You can add, modify, or delete any information at
            any time through the app's interface.`}
          </Text>
        </PrivacySection>
        <SectionHeading>Contact Us</SectionHeading>
        <Text>
          {`If you have any questions about this Privacy Policy, please contact us at: `}
          <TextLink to="mailto:hello@thehiveteam.co.nz">hello@thehiveteam.co.nz</TextLink>
        </Text>
      </Content>
    </ContentWrapper>
  );
};

export default PrivacyPolicy;
