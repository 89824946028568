import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Tile, { TileHeading } from './Tile';

const ProfileColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const ProfileContent = styled(ProfileColumn)`
  flex: 1 1 auto;
  margin-left: 16px;
`;

const ProfilePic = styled.div<{ $image: string }>`
  background-image: url('${(props) => props.$image}');
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 2px;
  height: 48px;
  width: 48px;
`;

interface ProfileProps {
  image: string;
  name: string;
};

const Profile: React.FC<PropsWithChildren<ProfileProps>> = ({
  children,
  image,
  name,
}) => {
  return (
    <Tile direction="row">
      <ProfileColumn>
        <ProfilePic $image={image} />
      </ProfileColumn>
      <ProfileContent>
        <ProfileColumn>
          <TileHeading>{name}</TileHeading>
          {children}
        </ProfileColumn>
      </ProfileContent>
    </Tile>
  );
};

export default Profile;
