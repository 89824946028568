import React from 'react';

const MealsSortedLogoBlack: React.FC = () => {
  return (
    <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.21888 11.7827L39.2733 1.85397C40.3202 7.32101 39.2014 11.9974 36.7635 15.6494H3.99966C3.30984 14.4857 2.71072 13.1982 2.21888 11.7827ZM1.9318 15.6494C1.35813 14.5617 0.853186 13.3837 0.427013 12.1128C0.138939 11.2537 0.660304 10.3342 1.5375 10.0992L39.0216 0.0553492C39.8988 -0.179696 40.8101 0.355914 40.9902 1.24394C42.131 6.87049 41.1681 11.7427 38.8897 15.6494H40.4032C41.3114 15.6494 42.0529 16.4026 41.997 17.3069C40.4026 43.0968 1.59739 43.0968 0.00296771 17.3069C-0.0529439 16.4026 0.688634 15.6494 1.59678 15.6494H1.9318Z" fill="white"/>
    </svg>
  );
};

export default MealsSortedLogoBlack;
