import styled from 'styled-components';

export const List = styled.ul`
  padding-left: 20px;
`;

export const ListItem = styled.li`
  &::marker {
    color: #55E4BA;
  }
`;
