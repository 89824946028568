import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { Container } from '../../components/mealssorted/Container';
import Footer from '../../components/mealssorted/Footer';
import Header from '../../components/mealssorted/Header';
import { Outlet } from 'react-router-dom';

// export const APP_DOWNLOAD_URL = 'https://apps.apple.com/nz/app/';
export const APP_DOWNLOAD_URL = '';

const theme = {
  berry: '#FF503C',
  liquorice: '#2C2429',
  white: '#FFFFFF',
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const MealsSorted: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Page>
        <Container>
          <Header />
          <Outlet />
        </Container>
        <Footer />
      </Page>
    </ThemeProvider>
  );
};

export default MealsSorted;
