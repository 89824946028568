import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { APP_DOWNLOAD_URL } from './MealsSorted';
import DownloadIos from '../../components/svgs/DownloadIos';

import mealSrc from '../../images/meal.png';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 992px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;
    justify-content: center;
  }

  @media (min-width: 1440px) {
    gap: 70px;
  }
`;

const Intro = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 42%;
  }
`;

const Heading = styled.h1`
  font-family: 'ClashDisplay-Bold', sans-serif;
  font-size: 48px;
  line-height: 1.1;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    white-space: pre;
  }

  @media (min-width: 1440px) {
    font-size: 64px;
  }
`;

const IntroText = styled.p`
  color: ${props => props.theme.liquorice}
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 35px;

  @media (min-width: 1440px) {
    font-size: 22px;
  }
`;

const Image = styled.img`
  max-width: 100%;

  @media (min-width: 992px) {
    width: 400px;
  }

  @media (min-width: 1440px) {
    width: 550px;
  }
`;

const Home: React.FC = () => {
  return (
    <Section>
      <Intro>
        <Heading>{'Your meals,\nsorted.'}</Heading>
        <IntroText>
          The Meals Sorted app saves you time and effort by helping you to plan your meals and sort your
          ingredients.
        </IntroText>
        {!APP_DOWNLOAD_URL && (
          <IntroText>Coming soon...</IntroText>
        )}
        {APP_DOWNLOAD_URL && (
          <Link to={APP_DOWNLOAD_URL}>
            <DownloadIos />
          </Link>
        )}
      </Intro>
      <Image src={mealSrc} />
    </Section>
  );
};

export default Home;
